<template>
  <b-container fluid class="component-performance-over-time-report-chart-account-type-stacked">
    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'performance-over-time-report-chart-account-type-stacked'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'
import defaultChartColorPalettes from '@/mixins/defaultChartColorPalettes'
import LifeplanBudgetReportMixin from '../inc/LifeplanBudgetReportMixin'

export default {
  name: 'LifeplanReportBarChartStacked',
  components: { BarChart },
  mixins: [chartOptions, defaultChartColorPalettes, LifeplanBudgetReportMixin],
  props: {
    apiData: { type: Object },
    currencySymbol: { type: String },
    resolution: { type: String },
    multiplier: { type: Number, default: 1 },
    activeTab: { type: String, default: 'income' },
    typeOfChartGroupOrAccount: { type: String, default: 'barchart-stacked-groups' },
    isLoaded: { type: Boolean, default: false },
    simulationType: { type: String, default: 'performance' }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.performance.performance-over-time.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded) {
        return false
      }

      // Chart options
      const suffix = ' ' + this.currencySymbol
      const aDatasets = []
      const labels = []
      const oPalette = this.getChartPalette()

      this.chartOptions.legend.display = true
      this.chartOptions.scales.yAxes[0].stacked = true
      this.chartOptions.scales.xAxes[0].stacked = true

      this.chartOptions.tooltips.callbacks.label = function (tooltipItem, data) {
        return data.datasets[tooltipItem.datasetIndex].label + ': ' + formatNumberToFull(tooltipItem.yLabel) + suffix
      }

      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return formatNumberToFull(value) + suffix
      }

      // Labels
      for (const sPeriodIdx in this.apiData.periods) {
        labels.push(this.apiData.periods[sPeriodIdx])
      }

      if (this.typeOfChartGroupOrAccount === 'barchart-stacked-groups') {
        // Account groups
        for (const iGroupIdx in this.apiData.groups[this.activeTab]) {
          const oGroup = this.apiData.groups[this.activeTab][iGroupIdx]
          const oTmpDataset = {}
          let iSum = 0

          oTmpDataset.label = oGroup.title
          oTmpDataset.data = []
          let aPeriodValues = []

          switch (this.simulationType) {
            case 'income':
            case 'expense':
            case 'asset':
            case 'liability':
            default:
              aPeriodValues = oGroup.period_totals
              break
          }

          for (const sPeriod in aPeriodValues) {
            oTmpDataset.data.push((aPeriodValues[sPeriod] * this.multiplier).toFixed(0))
            iSum += aPeriodValues[sPeriod]
          }

          // Only display categories with non-zero result
          if (iSum !== 0) {
            oTmpDataset.backgroundColor = oPalette.shift()
            aDatasets.push(oTmpDataset)
          }
        }
      } else if (this.typeOfChartGroupOrAccount === 'barchart-stacked-accounts') {
        // Accounts
        for (const iGroupIdx in this.apiData.groups[this.activeTab]) {
          const oGroup = this.apiData.groups[this.activeTab][iGroupIdx]
          let iSum = 0

          for (const iAcctIdx in oGroup.accounts) {
            const oAccount = oGroup.accounts[iAcctIdx]
            const oTmpDataset = {}

            oTmpDataset.label = oAccount.title
            oTmpDataset.data = []

            for (const sPeriod in oAccount.period_totals) {
              iSum += oAccount.period_totals[sPeriod]
              oTmpDataset.data.push((oAccount.period_totals[sPeriod] * this.multiplier).toFixed(0))
            }
            if (iSum !== 0) {
              oTmpDataset.backgroundColor = oPalette.shift()
              aDatasets.push(oTmpDataset)
            }
          }
        }
      }

      this.chartData = {
        labels: labels,
        datasets: aDatasets
      }
    }
  },
  watch: {
    apiData: {
      handler: 'fillChart',
      deep: true,
      immediate: true
    },
    isLoaded: {
      handler: 'fillChart',
      deep: true,
      immediate: true
    },
    accountResolution: {
      handler: 'fillChart',
      deep: true,
      immediate: true
    }
  }
}
</script>
