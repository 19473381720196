<template>
  <b-container fluid class="component-lifeplan-report-chart-stepped">
    <line-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'lifeplan-report-chart-stepped'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </line-chart>
  </b-container>
</template>

<script>
import LineChart from '@/components/chartjs/LineChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'

export default {
  name: 'LifeplanReportChartStepped',
  components: { LineChart },
  mixins: [chartOptions],
  props: {
    apiData: { type: Object },
    currencySymbol: { type: String },
    multiplier: { type: Number, default: 1 },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.budget.lifeplan.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded) {
        return false
      }

      // Chart options
      const suffix = ' ' + this.currencySymbol
      const tooltipLabelIncome = this.$t('common.incomes')
      const tooltipLabelExpense = this.$t('common.expenses')
      this.chartOptions.legend.display = true

      this.chartOptions.tooltips.callbacks.label = function (tooltipItem) {
        return (tooltipItem.datasetIndex === 0 ? tooltipLabelExpense : tooltipLabelIncome) + ': ' + formatNumberToFull(tooltipItem.yLabel) + suffix
      }

      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return formatNumberToFull(value) + suffix
      }

      // Chart data
      const labels = []

      const aIncomeDataset = {
        label: tooltipLabelIncome,
        data: [],
        type: 'bar',
        backgroundColor: 'rgba(140, 204, 173,0.7)'
      }

      const aExpenseDataset = {
        label: tooltipLabelExpense,
        data: [],
        type: 'line',
        backgroundColor: 'rgba(225, 165, 160,0.5)',
        borderColor: 'rgba(225, 165, 160,1)',
        steppedLine: 'left',
        fill: true
      }

      for (const sPeriod in this.apiData.totals.income.period_totals) {
        labels.push(sPeriod)
        aIncomeDataset.data.push((this.apiData.totals.income.period_totals[sPeriod] * this.multiplier).toFixed(0))
      }

      for (const sPeriod in this.apiData.totals.expense.period_totals) {
        aExpenseDataset.data.push((this.apiData.totals.expense.period_totals[sPeriod] * this.multiplier).toFixed(0))
      }

      this.chartData = {
        labels: labels,
        datasets: [aExpenseDataset, aIncomeDataset]
      }
    }
  },
  watch: {
    apiData: {
      handler: 'fillChart',
      deep: true,
      immediate: true
    },
    isLoaded: {
      handler: 'fillChart',
      deep: true,
      immediate: true
    }
  }
}
</script>
