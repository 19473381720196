<template>
  <b-container fluid class="reports-budget-lifeplan-bar-chart">
    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'reports-budget-lifeplan-bar-chart-id'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'
import LifeplanBudgetReportMixin from '../inc/LifeplanBudgetReportMixin'
import defaultChartColorPalettes from '@/mixins/defaultChartColorPalettes'

export default {
  name: 'LifeplanBudgetReportBarChart',
  components: { BarChart },
  mixins: [chartOptions, LifeplanBudgetReportMixin, defaultChartColorPalettes],
  props: {
    apiData: { type: Object },
    simulationType: { type: String, default: 'performance' },
    currencySymbol: { type: String },
    resolution: { type: String },
    multiplier: { type: Number, default: 1 },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.budget.lifeplan.charts.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded) {
        return false
      }

      // Chart options
      const suffix = ' ' + this.currencySymbol
      this.chartOptions.legend.display = true
      let tooltipLabel = null

      // Chart data
      const labels = []
      const chartData = []
      const backgroundColor = []
      let aPeriodDataSource = []
      let sDatasetLabel = ''
      let sPrimaryColor = this.getGreen()
      let sSecondaryColor = this.getRed()

      console.log(this.simulationType)

      switch (this.simulationType) {
        case 'result-per-year':
          aPeriodDataSource = this.apiData.totals.result.period_totals
          sDatasetLabel = this.$t(this.translationPath + 'labels.result-per-year')
          tooltipLabel = this.$t('common.result') + ': '
          break
        case 'asset-withdrawal':
          aPeriodDataSource = this.apiData.totals.asset.withdrawal
          sDatasetLabel = this.$t(this.translationPath + 'labels.withdrawal')
          tooltipLabel = this.$t('common.result') + ': '
          break
        case 'balance-per-year':
          aPeriodDataSource = this.apiData.totals.networth.period_totals
          sDatasetLabel = this.$t(this.translationPath + 'labels.balance-per-year')
          tooltipLabel = this.$t('common.networth') + ': '
          break
        case 'performance':
          aPeriodDataSource = this.apiData.totals.result.period_totals
          sDatasetLabel = this.$t(this.translationPath + 'labels.result')
          tooltipLabel = this.$t('common.result') + ': '
          break
        case 'income':
          aPeriodDataSource = this.apiData.totals.income.period_totals
          sDatasetLabel = this.$t(this.translationPath + 'labels.income')
          tooltipLabel = this.$t('common.incomes') + ': '
          break
        case 'expense':
          aPeriodDataSource = this.apiData.totals.expense.period_totals
          sDatasetLabel = this.$t(this.translationPath + 'labels.expense')
          tooltipLabel = this.$t('common.expenses') + ': '
          sPrimaryColor = this.getRed()
          sSecondaryColor = this.getRed()
          break
        case 'asset':
          aPeriodDataSource = this.apiData.totals.asset.period_totals
          sDatasetLabel = this.$t(this.translationPath + 'labels.asset')
          tooltipLabel = this.$t('common.assets') + ': '
          sPrimaryColor = this.getYellow()
          sSecondaryColor = this.getYellow()
          break
        case 'liability':
          aPeriodDataSource = this.apiData.totals.liability.period_totals
          sDatasetLabel = this.$t(this.translationPath + 'labels.liability')
          tooltipLabel = this.$t('common.liability') + ': '
          sPrimaryColor = this.getPurple()
          sSecondaryColor = this.getPurple()
          break
      }

      for (const sPeriod in aPeriodDataSource) {
        labels.push(this.translatePeriodColumnTitleIntoNiceName(sPeriod, this.resolution))
        chartData.push((aPeriodDataSource[sPeriod] * this.multiplier).toFixed(0))
        backgroundColor.push(aPeriodDataSource[sPeriod] >= 0 ? sPrimaryColor : sSecondaryColor)
      }

      this.chartData = {
        labels: labels,
        datasets: [
          {
            label: sDatasetLabel,
            data: chartData,
            type: 'bar',
            backgroundColor: backgroundColor
          }
        ]
      }

      this.chartOptions.tooltips.callbacks.label = function (tooltipItem) {
        return tooltipLabel + ' ' + formatNumberToFull(tooltipItem.yLabel) + suffix
      }

      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return formatNumberToFull(value) + suffix
      }
    }
  },
  watch: {
    apiData: {
      handler: 'fillChart',
      deep: true,
      immediate: true
    },
    isLoaded: {
      handler: 'fillChart',
      deep: true,
      immediate: true
    }
  }
}
</script>
