<template>
  <b-container fluid class="component-lifeplan-report-barchart-grouped">
    <bar-chart
      :chart-data="this.chartData"
      :options="this.chartOptions"
      :chart-id="'lifeplan-report-barchart-grouped'"
      :width="100"
      :height="400"
      :css-classes="''"
      :plugins="[]"
    >
    </bar-chart>
  </b-container>
</template>

<script>
import BarChart from '@/components/chartjs/BarChart'
import chartOptions from '@/mixins/defaultChartJsOptions'
import formatNumberToFull from '@/assets/filters/formatNumberToFull'
import defaultChartColorPalettes from '@/mixins/defaultChartColorPalettes'
import LifeplanBudgetReportMixin from '../inc/LifeplanBudgetReportMixin'

export default {
  name: 'LifeplanReportBarChartGrouped',
  components: { BarChart },
  mixins: [chartOptions, defaultChartColorPalettes, LifeplanBudgetReportMixin],
  props: {
    apiData: { type: Object },
    currencySymbol: { type: String },
    simulationType: { type: String, default: 'income-vs-expenses-grouped-chart' },
    resolution: { type: String },
    multiplier: { type: Number, default: 1 },
    activeTab: { type: String, default: 'income_groups' },
    isLoaded: { type: Boolean, default: false }
  },
  data () {
    return {
      chartData: {},
      translationPath: 'reports.performance.performance-over-time.'
    }
  },
  methods: {
    fillChart () {
      if (!this.isLoaded) {
        return false
      }
      const labels = []
      const aDatasets = []
      const aDataset01 = { type: 'bar', data: [] }
      const aDataset02 = { type: 'bar', data: [] }
      const suffix = ' ' + this.currencySymbol

      // Chart options
      this.chartOptions.legend.display = true

      this.chartOptions.tooltips.callbacks.label = function (tooltipItem) {
        return aDatasets[tooltipItem.datasetIndex].label + ': ' + formatNumberToFull(tooltipItem.yLabel) + suffix
      }

      this.chartOptions.scales.yAxes[0].ticks.callback = function (value, index, values) {
        return formatNumberToFull(value) + suffix
      }

      for (const sPeriod in this.apiData.periods) {
        labels.push(this.translatePeriodColumnTitleIntoNiceName(this.apiData.periods[sPeriod], this.resolution))
      }

      if (this.simulationType === 'income-vs-expenses-grouped-chart') {
        aDataset01.label = this.$t('common.incomes')
        aDataset01.backgroundColor = this.getGreen()
        aDataset01.aDatasetApiSource = this.apiData.totals.income.period_totals

        aDataset02.label = this.$t('common.expenses')
        aDataset02.backgroundColor = this.getRed()
        aDataset02.aDatasetApiSource = this.apiData.totals.expense.period_totals
      } else if (this.simulationType === 'assets-vs-liabilities-grouped-chart') {
        aDataset01.label = this.$t('common.assets')
        aDataset01.backgroundColor = this.getYellow()
        aDataset01.aDatasetApiSource = this.apiData.totals.asset.period_totals

        aDataset02.label = this.$t('common.liabilities')
        aDataset02.backgroundColor = this.getPurple()
        aDataset02.aDatasetApiSource = this.apiData.totals.liability.period_totals
      }

      for (const sPeriodIdx in this.apiData.periods) {
        const sPeriod = this.apiData.periods[sPeriodIdx]
        aDataset01.data.push(aDataset01.aDatasetApiSource[sPeriod] * this.multiplier)
        aDataset02.data.push(aDataset02.aDatasetApiSource[sPeriod] * this.multiplier)
      }

      aDatasets.push(aDataset01)
      aDatasets.push(aDataset02)

      this.chartData = {
        labels: labels,
        datasets: aDatasets
      }
    }
  },
  watch: {
    isLoaded: { // Handles period change
      handler: 'fillChart',
      deep: true,
      immediate: true
    },
    activeTab: { // Handles switch between tabs
      handler: 'fillChart',
      deep: true,
      immediate: true
    }
  }
}
</script>
