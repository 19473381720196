<template>
  <div class="component-reports-long-term-performance-budget-table-accounts">
    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters>
                <b-col cols="12" xl="4" class="mb-2">
                  <h2>{{ $t(translationPath + 'title_' + accountType, { start_year: yearStart, end_year: yearEnd }) }}</h2>
                </b-col>
                <b-col cols="12" xl="8" class="text-right">
                  <toggle-budgets-dropdown
                    v-if="budgets.length > 1"
                    ref="BudgetDropdown"
                    :budgets="budgets"
                    :initial-budget-id="budgetId"
                    @change="onBudgetChange"
                  />
                  <column-toggler v-if="false" :columns="options" @toggle="onColumnToggle" />
                  <b-dropdown right
                              :text="$t('common.toggle_chart') + ' | ' + $t( 'reports.budget.lifeplan.charts.'+showChart)"
                              variant='outline-secondary'
                              class="float-right mr-3 toggleColumnWrapper"
                              size="sm"
                  >
                    <b-dropdown-item @click="toggleChart('barchart')" v-bind:class="{ active: (showChart === 'barchart') }">{{$t('reports.budget.lifeplan.charts.barchart')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('barchart-stacked-groups')" v-bind:class="{ active: (showChart === 'barchart-stacked-groups') }">{{$t('reports.budget.lifeplan.charts.barchart-stacked-groups')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('barchart-stacked-accounts')" v-bind:class="{ active: (showChart === 'barchart-stacked-accounts') }">{{$t('reports.budget.lifeplan.charts.barchart-stacked-accounts')}}</b-dropdown-item>

                    <template v-if="accountType === 'asset'">
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item @click="toggleChart('asset-withdrawal')" v-bind:class="{ active: (showChart === 'asset-withdrawal') }">{{$t('reports.budget.lifeplan.charts.asset-withdrawal')}}</b-dropdown-item>
                      <b-dropdown-item @click="toggleChart('asset-withdrawal-stacked-groups')" v-bind:class="{ active: (showChart === 'asset-withdrawal-stacked-groups') }">{{$t('reports.budget.lifeplan.charts.asset-withdrawal-stacked-groups')}}</b-dropdown-item>
                      <b-dropdown-item @click="toggleChart('asset-withdrawal-stacked-accounts')" v-bind:class="{ active: (showChart === 'asset-withdrawal-stacked-accounts') }">{{$t('reports.budget.lifeplan.charts.asset-withdrawal-stacked-accounts')}}</b-dropdown-item>
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item @click="toggleChart('asset-deposit')" v-bind:class="{ active: (showChart === 'asset-deposit') }">{{$t('reports.budget.lifeplan.charts.asset-deposit')}}</b-dropdown-item>
                      <b-dropdown-item @click="toggleChart('asset-deposit-stacked-groups')" v-bind:class="{ active: (showChart === 'asset-deposit-stacked-groups') }">{{$t('reports.budget.lifeplan.charts.asset-deposit-stacked-groups')}}</b-dropdown-item>
                      <b-dropdown-item @click="toggleChart('asset-deposit-stacked-accounts')" v-bind:class="{ active: (showChart === 'asset-deposit-stacked-accounts') }">{{$t('reports.budget.lifeplan.charts.asset-deposit-stacked-accounts')}}</b-dropdown-item>
                    </template>
                    <template v-if="accountType === 'liability'">
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item @click="toggleChart('liability-interest')" v-bind:class="{ active: (showChart === 'liability-interest') }">{{$t('reports.budget.lifeplan.charts.liability-interest')}}</b-dropdown-item>
                      <b-dropdown-item @click="toggleChart('liability-interest-stacked-groups')" v-bind:class="{ active: (showChart === 'liability-interest-stacked-groups') }">{{$t('reports.budget.lifeplan.charts.liability-interest-stacked-groups')}}</b-dropdown-item>
                      <b-dropdown-item @click="toggleChart('liability-interest-stacked-accounts')" v-bind:class="{ active: (showChart === 'liability-interest-stacked-accounts') }">{{$t('reports.budget.lifeplan.charts.liability-interest-stacked-accounts')}}</b-dropdown-item>
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item @click="toggleChart('liability-amortization')" v-bind:class="{ active: (showChart === 'liability-amortization') }">{{$t('reports.budget.lifeplan.charts.liability-amortization')}}</b-dropdown-item>
                      <b-dropdown-item @click="toggleChart('liability-amortization-stacked-groups')" v-bind:class="{ active: (showChart === 'liability-amortization-stacked-groups') }">{{$t('reports.budget.lifeplan.charts.liability-amortization-stacked-groups')}}</b-dropdown-item>
                      <b-dropdown-item @click="toggleChart('liability-amortization-stacked-accounts')" v-bind:class="{ active: (showChart === 'liability-amortization-stacked-accounts') }">{{$t('reports.budget.lifeplan.charts.liability-amortization-stacked-accounts')}}</b-dropdown-item>
                    </template>

                  </b-dropdown>
                </b-col>
              </b-row>
            </template>
            <div class="col-lg-8 pl-0" v-html="$t(translationPath + 'description_' + accountType)"></div>

            <b-table
              class="spirecta-simple-table performance-over-time-table mb-0"
              show-empty
              hover
              responsive
              striped
              stacked="md"
              :items="tableData"
              :fields="tableColumns"
              :filter="filter"
              :filter-included-fields="['title','title_with_code']"
              :busy="!isLoaded"
              :tbodyTrClass="tableRowSetClass"
            >
              <template v-slot:table-busy><loader /></template>

              <template slot="top-row">
                <td :colspan="(this.tableColumns.length)">
                  <div class="d-flex align-items-center">
                    <i class="fa fa-search text-secondary"></i>
                    <b-form-input v-model="filter" size="sm" :placeholder="$t('common.filter_placeholder')"/>
                  </div>
                </td>
              </template>

              <!-- title slot-->
              <template v-slot:cell(title)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-if="row.item.row_type === 'account'">
                  <b-link :to="'/reports/performance/accounts/'+row.item.id+'/view/budget_simulation'">
                    {{ currentCOA.prefix_account_title_with_code ? row.item.title_with_code : row.item.title }}
                  </b-link>
                </template>
                <template v-else-if="row.item.row_type === 'account_group'">
                  <b-link :id="'group-' + row.item.id" :to="'/reports/performance/account-groups/' + row.item.id + '/twelve-months-rolling'">{{ currentCOA.prefix_account_title_with_code ? row.item.title_with_code : row.item.title }}</b-link>
                </template>
                <template v-else-if="row.item.row_type === 'part-sum'">
                  {{$t('common.partsum')}}
                </template>
                <template v-else-if="row.item.row_type === 'total'">
                  {{$t('common.total')}}
                </template>
              </template>

              <!-- Period slots -->
              <template v-slot:[gomycell(period)]="row" v-for="period in isLoaded ? this.apiData.periods : []">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else-if="row.item.row_type === 'account_group'">{{row.unformatted}}</template>
                <template v-else-if="row.item.row_type === 'account'">
                  {{ row.value }}
                </template>
                <template v-else-if="row.item.row_type === 'part-sum'">
                  {{ row.value }}
                </template>
                <template v-else>
                  {{row.value}}
                </template>
              </template>

              <!-- total slot-->
              <template v-slot:cell(total)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else>{{row.value}}</template>
              </template>

              <!-- total_percentage slot-->
              <template v-slot:cell(total_percentage)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else>{{row.value}}</template>
              </template>

              <!-- average slot-->
              <template v-slot:cell(average)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else>{{ row.value }}</template>
              </template>

              <!-- average_with_std_dev slot-->
              <template v-slot:cell(average_with_std_dev)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else>{{amountFormatter(row.item.average)}} &plusmn; {{amountFormatter(row.item.std_dev)}}</template>
              </template>

              <!-- std_dev slot-->
              <template v-slot:cell(std_dev)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else>{{ row.value }}</template>
              </template>

              <!-- min slot-->
              <template v-slot:cell(min)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else>{{row.value}}</template>
              </template>

              <!-- max slot-->
              <template v-slot:cell(max)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else>{{row.value}}</template>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Loader from '@/components/common/Loader'
import LifeplanBudgetReportMixin from '../inc/LifeplanBudgetReportMixin'
import ColumnToggler from '@/components/common/ColumnToggler'
import ToggleBudgetsDropdown from '@/components/common/ToggleBudgetsDropdown'

export default {
  name: 'LifeplanBudgetReportTableAccounts',
  mixins: [LifeplanBudgetReportMixin],
  components: { Loader, ColumnToggler, ToggleBudgetsDropdown },
  props: {
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    isLoaded: { type: Boolean, default: false },
    activeTab: { type: String, default: '' },
    accountType: { type: String, default: 'income' },
    resolution: { type: String, default: 'month' },
    selectedChart: { type: String, default: 'barchart' },
    apiData: {
      type: Object,
      default: function () {
        return {}
      }
    },
    options: {
      type: Object,
      default: function () {
        return {}
      }
    },
    budgets: {
      type: Array,
      default: function () {
        return []
      }
    },
    budgetId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      translationPath: 'reports.budget.lifeplan.table.accounts.',
      showChart: 'result-bar-chart',
      tableData: [],
      tableColumns: [],
      filter: ''
    }
  },
  computed: {
    yearStart () {
      return this.startDate.substr(0, 4)
    },
    yearEnd () {
      return this.endDate.substr(0, 4)
    }
  },
  methods: {
    onColumnToggle (data) {
      this.$emit('column-toggle', data)
    },
    toggleChart (showChart) {
      this.$emit('chart-to-display', { from_tab: 'accounts', chart: showChart })
      this.showChart = showChart
    },
    createTransactionHref (accountId, period, isAccountGroup) {
      let periodStartDate = ''
      let periodEndDate = ''

      if (this.resolution === 'month') {
        periodStartDate = period + '-01'
        const oTmpDate = new Date(periodStartDate)
        periodEndDate = new Date(oTmpDate.getFullYear(), oTmpDate.getMonth() + 1, 1)
        periodEndDate = periodEndDate.toISOString().split('T')[0]
      } else {
        periodStartDate = period + '-01-01'
        periodEndDate = period + '-12-31'
      }

      const sAccountGroupTitle = isAccountGroup ? 'account_group_id' : 'account_id'

      return '/transactions/view?' + sAccountGroupTitle + '=' + accountId + '&start_date=' + periodStartDate + '&end_date=' + periodEndDate
    }
  },
  mounted () {
    this.showChart = this.selectedChart
  },
  watch: {
    apiData: {
      handler: function () {
        this.tableData = this.buildTableDataRows('accounts', this.isLoaded, this.apiData, this.accountType, this.resolution)
        this.tableColumns = this.buildTableColumns('accounts', this.isLoaded, this.apiData, this.resolution)
      },
      deep: true,
      immediate: true
    },
    isLoaded: {
      handler: function () {
        this.tableData = this.buildTableDataRows('accounts', this.isLoaded, this.apiData, this.accountType, this.resolution)
        this.tableColumns = this.buildTableColumns('accounts', this.isLoaded, this.apiData, this.resolution)
      },
      deep: true,
      immediate: true
    },
    accountType: {
      handler: function () {
        this.tableData = this.buildTableDataRows('accounts', this.isLoaded, this.apiData, this.accountType, this.resolution)
        this.tableColumns = this.buildTableColumns('accounts', this.isLoaded, this.apiData, this.resolution)
      },
      deep: true,
      immediate: true
    },
    options: {
      handler: function () {
        this.tableColumns = this.buildTableColumns('accounts', this.isLoaded, this.apiData, this.resolution)
      },
      deep: true,
      immediate: true
    },
    budgetId () {
      process.nextTick(() => {
        if (this.$refs.BudgetDropdown) {
          this.$refs.BudgetDropdown.$selectItem(this.budgetId)
        }
      })
    }
  }
}
</script>
