<template>
  <div class="component-reports-long-term-performance-budget-table-result">
    <b-container fluid class="px-0 mt-4 settings-tab">
      <b-row no-gutters>
        <b-col>
          <b-card class="main-gradient-content-card borderlight pt-3 mx-xl-auto">
            <template v-slot:header>
              <b-row no-gutters>
                <b-col cols="12" xl="5" class="mb-2">
                  <h2>{{ $t(translationPath + 'title_result', {start_year: yearStart, end_year: yearEnd }) }}</h2>
                </b-col>
                <b-col cols="12" xl="7" class="text-right">
                  <toggle-budgets-dropdown
                    v-if="budgets.length > 1"
                    ref="BudgetDropdown"
                    :budgets="budgets"
                    :initial-budget-id="budgetId"
                    @change="onBudgetChange"
                  />
                  <column-toggler v-if="false" :columns="options" @toggle="onColumnToggle" />
                  <b-dropdown right
                              :text="$t('common.toggle_chart') + ' | ' + $t( 'reports.budget.lifeplan.charts.'+showChart)"
                              variant='outline-secondary'
                              class="float-right mr-3 toggleColumnWrapper"
                              size="sm"
                  >
                    <b-dropdown-item @click="toggleChart('income-vs-expenses-stepped-chart')" v-bind:class="{ active: showChart === 'income-vs-expenses-stepped-chart' }">{{$t( 'reports.budget.lifeplan.charts.income-vs-expenses-stepped-chart')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('income-vs-expenses-grouped-chart')" v-bind:class="{ active: showChart === 'income-vs-expenses-grouped-chart' }">{{$t('reports.budget.lifeplan.charts.income-vs-expenses-grouped-chart')}}</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item @click="toggleChart('result-per-year')" v-bind:class="{ active: showChart === 'result-per-year' }">{{$t( 'reports.budget.lifeplan.charts.result-per-year')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('performance-result-area-chart-acc')" v-bind:class="{ active: (showChart === 'performance-result-area-chart-acc') }">{{$t('reports.budget.lifeplan.charts.performance-result-area-chart-acc')}}</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item @click="toggleChart('balance-per-year')" v-bind:class="{ active: showChart === 'balance-per-year' }">{{$t( 'reports.budget.lifeplan.charts.balance-per-year')}}</b-dropdown-item>
                    <b-dropdown-item @click="toggleChart('assets-vs-liabilities-grouped-chart')" v-bind:class="{ active: showChart === 'assets-vs-liabilities-grouped-chart' }">{{$t( 'reports.budget.lifeplan.charts.assets-vs-liabilities-grouped-chart')}}</b-dropdown-item>
                  </b-dropdown>
                </b-col>
              </b-row>
            </template>

            <div class="col-lg-8 pl-0" v-html="$t(translationPath + 'description_result', {start_year: yearStart, end_year: yearEnd })"></div>

            <b-table
              class="spirecta-simple-table long-term-performance-budget-table mb-0"
              show-empty
              hover
              responsive
              striped
              stacked="md"
              :items="tableData"
              :fields="tableColumns"
              :busy="!isLoaded"
              :tbodyTrClass="tableRowSetClass"
            >
              <template v-slot:table-busy><loader /></template>

              <template v-slot:cell(title)="row">
                <template v-if="row.item.row_type === 'total'">{{ row.value }}</template>
                <template v-else><b-link href="#" @click="$emit('click-title', { tab: row.item.account_type })">{{ row.value }}</b-link></template>
              </template>

              <!-- Period slots -->
              <template v-slot:[gomycell(sPeriod)]="row" v-for="sPeriod in isLoaded ? this.apiData.periods : []">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else-if="row.item.row_type === 'account-group'">{{ row.field.label }}</template>
                <template v-else-if="row.item.row_type === 'total'">
                  <span :class="row.unformatted >= 0 ? 'text-green' : 'text-red'" v-bind:key="sPeriod">{{row.value}}</span>
                </template>
                <template v-else>{{row.value}}</template>
              </template>

              <!-- total slot-->
              <template v-slot:cell(total)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else-if="row.item.row_type === 'account-group'">{{ row.field.label }}</template>
                <template v-else>{{row.value}}</template>
              </template>

              <!-- average slot-->
              <template v-slot:cell(average_with_std_dev)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else-if="row.item.row_type === 'account-group'">{{ row.field.label }}</template>
                <template v-else>{{amountFormatter(row.item.average)}} &plusmn; {{amountFormatter(row.item.std_dev)}}</template>
              </template>

              <!-- average slot-->
              <template v-slot:cell(average)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else-if="row.item.row_type === 'account-group'">{{ row.field.label }}</template>
                <template v-else>{{row.value}}</template>
              </template>

              <!-- std_dev slot-->
              <template v-slot:cell(std_dev)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else-if="row.item.row_type === 'account-group'">{{ row.field.label }}</template>
                <template v-else>{{row.value}}</template>
              </template>

              <!-- min slot-->
              <template v-slot:cell(min)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else-if="row.item.row_type === 'account-group'">{{ row.field.label }}</template>
                <template v-else>{{row.value}}</template>
              </template>

              <!-- max slot-->
              <template v-slot:cell(max)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else-if="row.item.row_type === 'account-group'">{{ row.field.label }}</template>
                <template v-else>{{row.value}}</template>
              </template>

              <template v-slot:cell(total_percentage)="row">
                <template v-if="row.item.row_type === 'empty-row'"></template>
                <template v-else-if="row.item.row_type === 'account-group'">{{ row.field.label }}</template>
                <template v-else>{{row.value}}</template>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Loader from '@/components/common/Loader'
import LifeplanBudgetReportMixin from '../inc/LifeplanBudgetReportMixin'
import ColumnToggler from '@/components/common/ColumnToggler'
import ToggleBudgetsDropdown from '@/components/common/ToggleBudgetsDropdown'

export default {
  name: 'LifeplanBudgetReportTableResult',
  mixins: [LifeplanBudgetReportMixin],
  components: { Loader, ColumnToggler, ToggleBudgetsDropdown },
  props: {
    startDate: { type: String, default: null },
    endDate: { type: String, default: null },
    isLoaded: { type: Boolean, default: false },
    activeTab: { type: String, default: '' },
    resolution: { type: String, default: 'month' },
    selectedChart: { type: String, default: 'income-vs-expenses-stepped-chart' },
    options: {
      type: Object,
      default: function () {
        return {}
      }
    },
    apiData: {
      type: Object,
      default: function () {
        return {
          periods: []
        }
      }
    },
    budgets: {
      type: Array,
      default: function () {
        return []
      }
    },
    budgetId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      translationPath: 'reports.budget.lifeplan.table.summary.',
      tableData: [],
      showAccTableRow: false,
      showChart: 'result-per-year',
      ieCharts: [
        'result-per-year',
        'performance-result-area-chart-acc',
        'income-vs-expenses-grouped-chart',
        'income-vs-expenses-stepped-chart'
      ],
      alCharts: [
        'balance-per-year',
        'balance-result-area-chart-acc',
        'assets-vs-liabilities-grouped-chart'
      ]
    }
  },
  computed: {
    tableColumns () {
      return this.buildTableColumns('accounts', this.isLoaded, this.apiData, this.resolution)
    },
    yearStart () {
      return this.startDate.substr(0, 4)
    },
    yearEnd () {
      return this.endDate.substr(0, 4)
    }
  },
  mounted () {
    this.showChart = this.selectedChart
  },
  methods: {
    toggleChart (showChart) {
      this.showChart = showChart
      this.makeRows()
      this.$emit('chart-to-display', { from_tab: 'summary', chart: showChart })
    },
    onColumnToggle (data) {
      this.$emit('column-toggle', data)
    },
    makeRows () {
      if (!this.isLoaded) { return [] }

      this.tableData = []

      if (this.ieCharts.indexOf(this.showChart) !== -1) {
        // Use mixin function to build income/expense account table
        this.tableData.push({ row_type: 'account-group', title: this.$t('common.incomes') })
        this.tableData = [...this.tableData, ...this.buildTableDataRows('account_groups', this.isLoaded, this.apiData, 'income', this.resolution)]
        this.tableData.push({ row_type: 'empty-row', title: '', average: 0, total: 0 })
        this.tableData.push({ row_type: 'account-group', title: this.$t('common.expenses') })
        this.tableData = [...this.tableData, ...this.buildTableDataRows('account_groups', this.isLoaded, this.apiData, 'expense', this.resolution)]
        this.tableData.push({ row_type: 'empty-row', title: '', average: 0, total: 0 })

        this.tableData = this.tableData.map((item) => {
          if (item.row_type === 'total') {
            item.row_type = 'subtotal'
          }
          return item
        })

        // Total row
        const oTotalRow = Object.assign({ title: this.$t('common.result'), row_type: 'total' }, this.apiData.totals.result)
        for (const index in this.apiData.periods) {
          const sPeriod = this.apiData.periods[index]
          oTotalRow[sPeriod] = this.apiData.totals.result.period_totals[sPeriod]
        }
        this.tableData.push(oTotalRow)
      } else if (this.alCharts.indexOf(this.showChart) !== -1) {
        // Use mixin function to build asset/liability account table
        this.tableData.push({ row_type: 'account-group', title: this.$t('common.assets') })
        this.tableData = [...this.tableData, ...this.buildTableDataRows('account_groups', this.isLoaded, this.apiData, 'asset', this.resolution)]
        this.tableData.push({ row_type: 'empty-row', title: '', average: 0, total: 0 })
        this.tableData.push({ row_type: 'account-group', title: this.$t('common.liabilities') })
        this.tableData = [...this.tableData, ...this.buildTableDataRows('account_groups', this.isLoaded, this.apiData, 'liability', this.resolution)]
        this.tableData.push({ row_type: 'empty-row', title: '', average: 0, total: 0 })

        this.tableData = this.tableData.map((item) => {
          if (item.row_type === 'total') {
            item.row_type = 'subtotal'
          }
          return item
        })

        // Total row
        const oTotalRow = Object.assign({ title: this.$t('common.result'), row_type: 'total' }, this.apiData.totals.networth)
        for (const index in this.apiData.periods) {
          const sPeriod = this.apiData.periods[index]
          oTotalRow[sPeriod] = this.apiData.totals.networth.period_totals[sPeriod]
        }
        this.tableData.push(oTotalRow)
      } else {
        return []
      }

      for (const idx in this.tableData) {
        if (this.tableData[idx].row_type === 'account_group') {
          this.tableData[idx].row_type = 'account'
        }
      }
    }
  },
  watch: {
    apiData: {
      handler: 'makeRows',
      deep: true,
      immediate: true
    },
    isLoaded: {
      handler: 'makeRows',
      deep: true,
      immediate: true
    },
    activeTab: {
      handler: 'makeRows',
      deep: true,
      immediate: true
    },
    budgetId () {
      process.nextTick(() => {
        if (this.$refs.BudgetDropdown) {
          this.$refs.BudgetDropdown.$selectItem(this.budgetId)
        }
      })
    }
  }
}
</script>
